import React, { useRef, useState } from 'react';
import axios from 'axios';
import { Constants } from '../lib/const/constants';
import { PrimaryButton } from '../components/button';
import toast from 'react-hot-toast';
import ReactPaginate from 'react-paginate';
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

function BulkUpload() {
    const navigate = useNavigate();
    const [file, setFile] = useState()
    const [uploading, setUploading] = useState(false);
    const [fileName, setfileName] = useState("");
    // const [loading, setLoading] = useState(false);
    const [transactions, settransactions] = useState([]);
    const [showOverView, setshowOverView] = useState(false);
    const [totalRecords, settotalRecords] = useState();
    const [totalPayment, settotalPayment] = useState();
    const [uniqueId, setuniqueId] = useState();
    const reference = useRef(null)
    const handleSingleFileChange = (event) => {
        setFile(event.target.files[0])
        setfileName(event.target.files[0].name);
    };
    const getBase64 = async (file, cb) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }
    function handleSubmit() {
        if (file != null && file != '') {
            setUploading(true)
            getBase64(file, (result) => {
                uploadSingleDocument(result)
            });
        }
        else
            toast.error('Please upload file.')
    }
    function Items({ currentItems }) {
        Items.propTypes = {
            currentItems: PropTypes.any,
        };
        return (
            <>
                <div className='mt-10 mb-5 w-full align-center'>Uploaded Data</div>
                <table cellPadding={10} cellSpacing={10} className='w-full bg-[#ffffff]' >
                    {currentItems &&
                        currentItems.map((item, index) => {
                            return (
                                <>
                                    {index == 0 &&
                                        <tr style={{ borderWidth: 2, borderColor: '#392d5e', fontSize: 12, borderRadius: 10 }} key={index} className='text-sm'><td>Type</td><td>First Name</td><td>Last Name</td><td>Reason</td><td>Amount</td><td>Mobile</td><td>Country Name</td><td>Code Type</td><td>Account Name</td><td>Code</td><td>Account Number</td><td>Address</td><td>Company Name</td><td>Currency</td></tr>}
                                    <tr className='font-light text-sm' style={{ borderWidth: 2, borderColor: '#392d5e', fontSize: 12, borderRadius: 10 }} key={index + 1}>
                                        {item.map((row, rowindex) => {

                                            return (
                                                <td key={'row' + rowindex}>
                                                    {row}
                                                </td>
                                            )
                                        })}

                                    </tr>
                                </>
                            )
                        })
                    }
                </table>
            </>
        );
    }

    function PaginatedItems({ itemsPerPage }) {
        PaginatedItems.propTypes = {
            itemsPerPage: PropTypes.any,
        };
        // Here we use item offsets; we could also use page offsets
        // following the API or data you're working with.
        const [itemOffset, setItemOffset] = useState(0);

        // Simulate fetching items from another resources.
        // (This could be items from props; or items loaded in a local state
        // from an API endpoint with useEffect and useState)
        const endOffset = itemOffset + itemsPerPage;
        console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        const currentItems = transactions.slice(itemOffset, endOffset);
        const pageCount = Math.ceil(transactions.length / itemsPerPage);

        // Invoke when user click to request another page.
        const handlePageClick = (event) => {
            const newOffset = (event.selected * itemsPerPage) % transactions.length;
            console.log(
                `User requested page number ${event.selected}, which is offset ${newOffset}`
            );
            setItemOffset(newOffset);
        };
        return (
            <>
                <Items currentItems={currentItems} />
                <ReactPaginate
                    breakLabel="..."
                    nextLabel="next >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel="< previous"
                    renderOnZeroPageCount={null}
                    className='flex w-full my-5'
                    pageLinkClassName='mx-10'
                    activeClassName='text-lg text-[#ffffff] bg-[#392d5e] rounded-lg'
                />
            </>
        );
    }


    const uploadSingleDocument = async (document) => {
        const token = sessionStorage.getItem('login_token');
        await axios.post(Constants.BASE_URL + 'API-FX-195-BULK-UPLOAD-DOCUMENTS-TRANSACTIONS', {
            file: document,
            fileName: fileName
        }, {
            headers: {
                Authorization: "Bearer " + JSON.parse(token),
                fx_key: Constants.SUBSCRIPTION_KEY
            }
        }).then((response) => {
            console.log(response.data);
            if (response.data.status == 200) {
                settransactions(response.data.uploaded_transactions)
                settotalRecords(response.data.totalTransactions)
                settotalPayment(response.data.totalAmount)
                setuniqueId(response.data.uniqueId)
            }
            else {
                toast.error(response.data.message)
            }
            // toast.success('Uploaded Successfully.')
            setUploading(false)
            reference.current.value = "";
        }).catch(err => {
            err
            toast.error('Something went wrong.Please try again later.')
            setUploading(false)
        });
    }
    function OverView() {
        return (
            <table className='w-full bg-[#ffffff] my-10 rounded-lg' cellPadding={10} cellSpacing={10}>
                <tr><td>Total Payments: </td><td>{totalRecords}</td></tr>
                <tr><td>Total Amount: </td><td>{totalPayment}</td></tr>
            </table>
        )
    }
    const confirmUpload = async() =>{
        setUploading(true)
        const token = sessionStorage.getItem('login_token');
        await axios.post(Constants.BASE_URL+'API-FX-212-BULK-UPLOAD-CONFIRM',{
            uniqueId: uniqueId,
            fileName: fileName
        }, {
            headers: {
                Authorization: "Bearer " + JSON.parse(token),
                fx_key: Constants.SUBSCRIPTION_KEY
            }
        }).then(resp=>{
            if(resp.data.status=='200')
                {
                    toast.success(resp.data.message)
                    setTimeout(function(){
                        navigate('/');
                    },2000);
                }
            else
                toast.error(resp.data.message)
                setUploading(false)
        }).catch(err=>{
            err
            toast.error('Something went wrong.Please try again later.')
            setUploading(false)
        })
    }
    return (
        <div className="my-2">
            {transactions.length == 0 &&
                <div className="flex items-center justify-between py-4 mb-4">
                    <div className="flex items-center ml-7">
                        <span
                            className="font-medium text-lg border-l-4 border-l-[#F4CE14] pl-3">Bulk Upload</span>
                    </div>
                    <div className="flex space-x-4">
                        <a href='/testUpload.csv'>Download Test File</a>
                    </div>
                </div>}
            {transactions.length == 0 ?
                <div className="flex relative overflow-x-auto rounded-3xl bg-white p-5">
                    <div>
                        <input type='file' accept='.csv' ref={reference} onChange={(event) => handleSingleFileChange(event)} />
                    </div>
                    <div style={{ width: '10%' }}>
                        <PrimaryButton
                            label={"Upload"}
                            onClick={() => handleSubmit()}
                            loading={uploading}
                        />
                    </div>
                </div>
                :
                showOverView ?
                    <OverView />
                    :
                    <PaginatedItems itemsPerPage={10} />
            }
            <div className='w-full align-center text-center'>
                {transactions.length > 0 && (
                    showOverView ?
                        <PrimaryButton
                            label={'Confirm to upload'}
                            style={{ width: 200 }}
                            onClick={confirmUpload}
                            loading={uploading}
                        />
                        :
                        <PrimaryButton
                            label={'Proceed'}
                            style={{ width: 100 }}
                            onClick={() => setshowOverView(true)}
                        />
                )}
            </div>
        </div>
    );
}

export default BulkUpload;